import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, SnackbarProps, Alert, AlertProps } from "@mui/material";

interface SnackbarPropsExtended extends SnackbarProps {
  icon?: ReactNode;
  textColor?: string;
}
interface SnackbarMessage {
  message: string;
  options?: SnackbarPropsExtended;
  severity?: AlertProps["severity"];
}

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    options?: SnackbarPropsExtended,
    severity?: AlertProps["severity"],
  ) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbars, setSnackbars] = useState<SnackbarMessage[]>([]);

  const showSnackbar = (
    message: string,
    options?: SnackbarPropsExtended,
    severity?: AlertProps["severity"],
  ) => {
    setSnackbars((prev) => [...prev, { message, options, severity }]);
  };

  const hideSnackbar = () => {
    setSnackbars((prev) => prev.slice(1));
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      {snackbars.map((snackbar, index) => {
        return (
          <Snackbar
            key={index}
            open={true}
            onClose={hideSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            {...(snackbar.options as SnackbarProps)}
          >
            <Alert
              variant="standard"
              onClose={hideSnackbar}
              severity={snackbar.severity}
              icon={snackbar.options?.icon}
              sx={{ color: snackbar.options?.textColor || "text.main" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        );
      })}
    </SnackbarContext.Provider>
  );
};
