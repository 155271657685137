import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../store";
import { Report } from "../../models";
import { api } from "../../../../api/twelve";

interface SavedReportListState {
  items: Array<Report>;
  loading: boolean;
  canLoadingMore: boolean;
  error: string | null;
}

const initialState: SavedReportListState = {
  items: [],
  loading: false,
  canLoadingMore: true,
  error: null,
};

const savedReportListSlice = createSlice({
  name: "savedReportList",
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
      state.canLoadingMore = true;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{ items: Array<Report>; append?: boolean }>,
    ) => {
      if (action.payload.append) {
        state.items = [...state.items, ...action.payload.items];
      } else {
        state.items = action.payload.items;
      }
      state.canLoadingMore = action.payload.items.length > 0;
      state.loading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItem: (state, action: PayloadAction<Report>) => {
      state.items.unshift(action.payload);
    },
  },
});

export default savedReportListSlice.reducer;

// Thunk actions for API calls
export const fetchReports: any =
  (token: string, page?: number, limit?: number, search?: string) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(savedReportListSlice.actions.fetchItemsStart());
      const response = await api.getSavedReports(token, page, limit, search);
      dispatch(
        savedReportListSlice.actions.fetchItemsSuccess({
          items: response,
          append: page !== undefined && page > 1,
        }),
      );
    } catch (error: any) {
      console.error("Failed to fetch reports:", error);
      dispatch(savedReportListSlice.actions.failure(error.message));
    }
  };
