const sizes = {
  small: 24,
  large: 48,
};

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: "small" | "large";
}

export default function ReportLogo(props: Props) {
  const { size = "small" } = props;

  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{
        width: `${sizes[size]}px`,
        height: `${sizes[size]}px`,
        ...props.style,
      }}
    >
      <path
        d="M5.77123 0.244141H3.28125V12.4581H5.77123V0.244141Z"
        fill="#FF4B00"
      />
      <path
        d="M15.7182 3.83866C15.7182 1.39589 13.7156 0 11.5505 0C9.83689 0 8.19513 0.837186 7.38281 2.58206L9.47525 3.76823C9.88997 2.8958 10.6117 2.33713 11.5865 2.33713C12.5612 2.33713 13.2282 2.94823 13.2282 3.92467C13.2282 4.74468 12.5971 5.54747 11.7848 6.36748L7.47261 10.66V12.4572H8.7658L13.6429 7.74615C14.8341 6.59438 15.7174 5.32057 15.7174 3.83784L15.7182 3.83866Z"
        fill="#FF4B00"
      />
      <path
        d="M12.6362 10.1367L10.2344 12.4575H15.9981V10.1367H12.6362Z"
        fill="#FF4B00"
      />
      <path d="M2.2549 0.244141H0V2.40757H2.2549V0.244141Z" fill="#FF4B00" />
    </svg>
  );
}
