import { Box } from "@mui/material";
import styles from "../../App.module.css";
import PlayerScoutingReport from "./scouting/ScoutingReport";
import { useEffect, useState } from "react";
import { api } from "../../api/twelve";
import { useParams } from "react-router-dom";
import { ReportContent } from "./models";
import LoadingScreen from "./shared/LoadingScreen";

export default function PublicReportPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent | undefined>(
    undefined,
  );
  const { publicId } = useParams();

  useEffect(() => {
    if (!publicId) {
      return;
    }
    const getReport = async () => {
      setLoading(true);
      try {
        const reportContent = await api.getReportByPublicId(publicId);
        setReportContent(reportContent);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getReport();
  }, [publicId]);

  const renderReport = () => {
    switch (reportContent?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...reportContent} />;
      default:
        return <></>;
    }
  };

  return (
    <Box className={styles.publicReport}>
      {loading ? <LoadingScreen /> : renderReport()}
    </Box>
  );
}
