import { Typography, Box, useTheme } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface MarkdownRendererProps {
  text?: string;
}

export default function MarkdownText({ text = "" }: MarkdownRendererProps) {
  const theme = useTheme();
  return (
    <Typography component={"span"} variant={"body1"}>
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          code({
            inline,
            children,
            ...props
          }: {
            inline?: boolean;
            children?: React.ReactNode;
          }) {
            return inline ? (
              <Typography
                component="code"
                sx={{
                  backgroundColor: theme.palette.backgrounds.code,
                  borderRadius: "4px",
                  padding: "2px 4px",
                  fontSize: "10px",
                  fontFamily: "monospace",
                }}
                {...props}
              >
                {children}
              </Typography>
            ) : (
              <Box
                component="pre"
                sx={{
                  backgroundColor: theme.palette.backgrounds.code,
                  padding: "12px",
                  borderRadius: "5px",
                  fontSize: "10px",
                  overflow: "auto",
                  width: "calc(100% - 30px)",
                }}
                {...props}
              >
                <code>{children}</code>
              </Box>
            );
          },
        }}
      />
    </Typography>
  );
}
