import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth0 } from "@auth0/auth0-react";
import { Report } from "../models";
import PageLoader from "../../../shared/components/PageLoader";
import useDebounce from "../../../shared/hooks/useDebounce";
import { CustomRouterLink } from "../../../shared/components/CustomRouterLink";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "../store/slices/savedReportListSlice";
import DateTimeHelper from "../../../shared/helpers/datetime.helper";

export default function SavedReportsPage() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const listContainerRef = useRef<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const page = useRef<number>(1);
  const limit = 30;
  const { items, loading, canLoadingMore } = useSelector(
    (state: RootState) => state.reports.saved.list,
  );
  const [groupedItems, setGroupedItems] = useState<
    Record<string, Array<Report>>
  >({});

  const onScroll = async () => {
    if (listContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listContainerRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        canLoadingMore &&
        !loading &&
        canLoadingMore
      ) {
        page.current += 1;
        dispatch(
          fetchReports(
            await getAccessTokenSilently(),
            page.current,
            limit,
            debouncedSearchTerm,
          ),
        );
      }
    }
  };

  useEffect(() => {
    const getReports = async () => {
      page.current = 1;
      dispatch(
        fetchReports(
          await getAccessTokenSilently(),
          page.current,
          limit,
          debouncedSearchTerm,
        ),
      );
    };

    getReports();
  }, [getAccessTokenSilently, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    const groupedItems = items.reduce((acc, item) => {
      const month = DateTimeHelper.format(item.created_at, "MMMM YYYY");
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {} as Record<string, typeof items>);

    setGroupedItems(groupedItems);
  }, [items]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "660px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "48px 16px 16px 16px",
        }}
      >
        <Box>
          <TextField
            sx={{
              width: "100%",
            }}
            size="small"
            variant="outlined"
            placeholder="Search reports"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Box>
        <Box
          className="scrollable"
          ref={listContainerRef}
          onScroll={onScroll}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            overflow: "auto",
          }}
        >
          {Object.keys(groupedItems).map((month, index) => (
            <React.Fragment key={`report-group-${index}`}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "text.secondary",
                  }}
                >
                  {month}
                </Typography>
              </Box>
              <List
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "backgrounds.secondary",
                }}
              >
                {groupedItems[month].map((report, index) => (
                  <React.Fragment key={`report-item-${report.id}`}>
                    <ListItemButton
                      component={CustomRouterLink}
                      to={{
                        pathname: `/saved-reports/${report.id}`,
                        state: { report },
                      }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: "16px",
                        padding: "16px 12px",
                        textDecoration: "none",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "8px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "text.disabled",
                            fontSize: "12px",
                            fontWeight: 400,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textWrap: "nowrap",
                          }}
                        >
                          {report.subname?.replace(
                            "|",
                            `${report.context.competition.year}/${
                              report.context.competition.year + 1
                            } |`,
                          )}
                        </Typography>
                        {/* <ReportTypeBadge variant={report.report_type} /> */}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "text.primary",
                            textDecoration: "unset",
                          }}
                        >
                          {report.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 400,
                            color: "text.disabled",
                            textDecoration: "unset",
                          }}
                        >
                          {report.subsubname}
                        </Typography>
                      </Box>
                    </ListItemButton>
                    {index < groupedItems[month].length - 1 ? (
                      <Divider />
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </React.Fragment>
          ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="16px" />
            </Box>
          )}
        </Box>
        {loading && items.length === 0 ? <PageLoader variant="full" /> : <></>}
      </Box>
    </Box>
  );
}
