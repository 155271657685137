import { styled } from "@mui/material";

interface BoxProps {
  contentAlign?: "center" | "start" | "end";
}

// Could be a regular component, no real need for styled.

export const LayoutContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "contentAlign",
})<BoxProps>(({ contentAlign }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent:
    contentAlign === "center" ? contentAlign : "flex-" + contentAlign,
}));
