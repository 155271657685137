import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useEffect } from "react";
import { fetch } from "../../store/slices/reportTemplatesSlice";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { CustomRouterLink } from "../../../../shared/components/CustomRouterLink";

export default function ReportTemplatesList() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { items } = useSelector(
    (state: RootState) => state.reports.templates.list,
  );

  useEffect(() => {
    const getTemplates = async () => {
      dispatch(fetch(await getAccessTokenSilently()));
    };

    getTemplates();
  }, [getAccessTokenSilently, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        padding: 0,
      }}
    >
      <List
        className="scrollable"
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          padding: 0,
        }}
      >
        <ListSubheader>Report templates</ListSubheader>
        {items.map((report) => (
          <ListItemButton
            key={`report-${report.id}`}
            component={CustomRouterLink}
            to={{
              pathname: `/report/templates/${report.id}`,
              state: { report },
            }}
          >
            <ListItemText>
              <Typography
                sx={{
                  //fontWeight: report.id === Number(reportId) ? '600' : '200',
                  paddingLeft: "12px",
                  fontSize: "14px",
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {report.name}
              </Typography>
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
      <List
        sx={{
          flexShrink: 0,
        }}
      >
        <ListItemButton component={Link} to={`/report/templates/create`}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                paddingRight: "8px",
                letterSpacing: "0.4px",
              }}
            >
              Create new template
            </Typography>
            <AddIcon sx={{ fontSize: "16px" }} />
          </Box>
        </ListItemButton>
      </List>
    </Box>
  );
}
