import {
  Box,
  Divider,
  styled,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from "@mui/material";

interface BottomSwipeableDrawerProps extends SwipeableDrawerProps {
  title?: string;
}

const Puller = styled("div")(({ theme }) => ({
  width: 32,
  height: 4,
  backgroundColor: theme.palette.common.white,
  opacity: 0.1,
  borderRadius: 2,
  position: "absolute",
  top: 8,
  left: "calc(50% - 16px)",
}));

const BottomSwipeableDrawer: React.FC<BottomSwipeableDrawerProps> = ({
  children,
  title,
  ...rest
}: BottomSwipeableDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      PaperProps={{
        elevation: 1,
        sx: {
          borderRadius: "16px 16px 0 0",
          paddingTop: "16px",
        },
      }}
      slotProps={{
        root: {
          style: {
            zIndex: 9999,
          },
        },
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
      }}
      {...rest}
    >
      <Puller />
      <Box role="presentation">
        {title ? (
          <Box>
            <Typography
              variant="subtitle2"
              align="center"
              sx={{
                margin: "16px 0",
                fontWeight: 600,
                fontSize: "16px",
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              {title}
            </Typography>
            <Divider />
          </Box>
        ) : (
          <></>
        )}
        <Box>{children}</Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default BottomSwipeableDrawer;
