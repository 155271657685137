import { PaletteOptions } from "@mui/material";
import { defaultCustomColors } from "./customColors";

export const lightModeApplicationPalette: PaletteOptions = {
  mode: "light",
  divider: "#0000001A",
  text: {
    primary: "#000000DE",
    secondary: "#00000099",
    disabled: "#00000061",
  },
  action: {
    active: "#0000008F",
    hover: "#0000000A",
    selected: "#00000014",
    focus: "#0000001F",
    disabledBackground: "#0000001F",
  },
  primary: {
    main: "#014633",
    dark: "#002C1C",
    light: "#04553F",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#757575",
    dark: "#616161",
    light: "#E0E0E0",
    contrastText: "#FFFFFF",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF5350",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#D93700",
    dark: "#C12700",
    light: "#FF4D00",
    contrastText: "#FFFFFF",
  },
  info: {
    main: "#5A657E",
  },
  success: {
    main: "#009940",
    dark: "#008734",
    light: "#10A849",
    contrastText: "#FFFFFF",
  },
  disabled: {
    main: "#00000042",
  },
  background: {
    paper: "#FAFAFA",
    default: "#F5F5F5",
  },
  backgrounds: {
    primary: "#FAFAFA",
    secondary: "#FFFFFF",
    tertiary: "#FFFFFF14",
    code: "#6f6f6",
    bagde1: "#E3F2FD",
    menu: "#FAFAFA",
  },
  colors: {
    accent: "#FF4B00",
    bagde1: "#0288D1",
  },
  customColors: defaultCustomColors,
};

export const darkModeApplicationPalette: PaletteOptions = {
  mode: "dark",
  divider: "#FFFFFF1A",
  text: {
    primary: "#FFFFFF",
    secondary: "#FFFFFFB3",
    disabled: "#FFFFFF61",
  },
  action: {
    active: "#FFFFFF8F",
    hover: "#FFFFFF14",
    selected: "#FFFFFF29",
    focus: "#FFFFFF1F",
    disabledBackground: "#FFFFFF1F",
  },
  primary: {
    main: "#69C27F",
    dark: "#45B564",
    light: "#E4F4E8",
    contrastText: "#000000DE",
  },
  secondary: {
    main: "#B0BEC5",
    dark: "#90A4AE",
    light: "#ECEFF1",
    contrastText: "#000000DE",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  error: {
    main: "#E57373",
    dark: "#EF5350",
    light: "#FECDD2",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FF8458",
    dark: "#FF6832",
    light: "#FFA788",
    contrastText: "#000000",
  },
  info: {
    main: "#5A657E",
  },
  success: {
    main: "#69C27F",
    dark: "#45B564",
    light: "#E4F4E8",
    contrastText: "#000000",
  },
  disabled: {
    main: "#FFFFFF14",
  },
  background: {
    paper: "#001F14",
    default: "#00120C",
  },
  backgrounds: {
    primary: "#001A11",
    secondary: "#001F14",
    tertiary: "#FFFFFF14",
    code: "#6f6f6",
    bagde1: "#101010",
    menu: "#00291B",
  },
  colors: {
    bagde1: "#0288D1",
  },
  customColors: defaultCustomColors,
};
