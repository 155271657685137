import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../store";
import { Report } from "../../models";
import { api } from "../../../../api/twelve";

export interface SavedReportShortListState {
  items: Array<Report>;
  loading: boolean;
  error: string | null;
}

const initialState: SavedReportShortListState = {
  items: [],
  loading: false,
  error: null,
};

const savedReportShortListSlice = createSlice({
  name: "savedReportShortList",
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{ items: Array<Report> }>,
    ) => {
      state.items = action.payload.items;
      state.loading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItem: (state, action: PayloadAction<Report>) => {
      state.items.unshift(action.payload);
      state.items.slice(0, 5);
    },
  },
});

export default savedReportShortListSlice.reducer;

// Thunk actions for API calls
export const fetchSavedReports: any =
  (token: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(savedReportShortListSlice.actions.fetchItemsStart());
      const response = await api.getSavedReports(token, 1, 5);
      dispatch(
        savedReportShortListSlice.actions.fetchItemsSuccess({
          items: response,
        }),
      );
    } catch (error: any) {
      console.error("Failed to fetch saved reports:", error);
      dispatch(savedReportShortListSlice.actions.failure(error.message));
    }
  };
