import React, { FC, useState, ReactElement, ReactNode, useEffect } from "react";
import { CSS } from "@dnd-kit/utilities";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Box, Icon, useTheme } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface SortableItemProps {
  id: string;
  children: ReactNode;
}

const SortableItem: FC<SortableItemProps> = ({ id, children }) => {
  const { listeners, setNodeRef, transform, isDragging } = useSortable({ id });
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  const theme = useTheme();

  return (
    <Box
      ref={setNodeRef}
      className="sortable-item"
      sx={{
        position: "relative",
        transform: CSS.Transform.toString(
          isDragging ? ({ ...transform, scaleY: 1 } as any) : transform,
        ),
        backgroundColor: isDragging
          ? theme.palette.action.selected
          : "transparent",
        display: "flex",
        width: "100%",
        borderRadius: "4px",
        "&:hover>.drag-icon": {
          visibility: "visible",
          opacity: 1,
          width: "24px",
        },
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.2s ease-in-out",
        touchAction: "none",
        paddingLeft: "24px",
      }}
    >
      <Icon
        {...listeners}
        className="drag-icon"
        sx={{
          cursor: "grab",
          flexGrow: 0,
          marginTop: "4px",
          opacity: 0,
          transition: "opacity 0.3s ease",
          position: "absolute",
          left: "0",
        }}
      >
        <DragIndicatorIcon />
      </Icon>
      <Box sx={{ width: "100%" }}>{children}</Box>
    </Box>
  );
};

interface SortableListProps {
  children: ReactElement<{ id: string }>[];
  onSortEnd?: (newOrder: string[]) => void;
}

const SortableList: FC<SortableListProps> = ({ children, onSortEnd }) => {
  const [itemIds, setItemIds] = useState(
    children.map((child) => child.props.id),
  );
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItemIds((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        if (onSortEnd) {
          onSortEnd(newOrder);
        }

        return newOrder;
      });
    }
  };

  useEffect(() => {
    setItemIds(children.map((child) => child.props.id));
  }, [children]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
        {itemIds.map((id) =>
          React.Children.map(children, (child) =>
            child.props.id === id ? React.cloneElement(child) : null,
          ),
        )}
      </SortableContext>
    </DndContext>
  );
};

export { SortableItem, SortableList };
