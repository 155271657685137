import { Box, Typography, useMediaQuery, useTheme, alpha } from "@mui/material";
import { ReportPage, StandardContentReportSlide } from "../../models";
import SwipeableContent from "../../../../shared/components/SwipeableContent";
import {
  Page,
  PageBox,
  PageBoxContent,
  PageBoxHeader,
  PageContent,
  PageHeader,
} from "../../shared/ReportLayout";
import MarkdownText from "../../../../shared/components/MarkdownText";
import { Chart } from "../../../../shared/components/charts/Chart";
import ChartHelper from "../../../../shared/helpers/chart.helper";

export default function StandardPage({
  page,
  pageNumber,
}: {
  page: ReportPage;
  pageNumber: number;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const indicatorBorderColor = alpha(
    theme.palette.customColors.text,
    theme.palette.customColors.opacity.text.primary,
  );

  return (
    <Page id={`page-${pageNumber}`} key={`page-${pageNumber}`}>
      <PageHeader
        title={page.header}
        subtitle={!isMobile ? page.subheader : undefined}
      />
      {!isMobile ? (
        <Box sx={{ position: "relative" }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "34px",
              color: alpha(
                theme.palette.customColors.text,
                theme.palette.customColors.opacity.text.primary,
              ),
              marginBottom: "20px",
            }}
          >
            {page.main_header}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
      <PageContent sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            height: "100%",
            [theme.breakpoints.up("xl")]: {
              maxHeight: "620px",
            },
          }}
        >
          <SwipeableContent
            allowExpand={false}
            indicatorsVariant={isMobile ? "circle" : "button"}
            indicatorsStyle={{ borderColor: indicatorBorderColor }}
            descriptions={
              isMobile
                ? page.slides?.map(
                    (slide: StandardContentReportSlide) => slide.description,
                  )
                : undefined
            }
          >
            {page.slides
              ?.sort(
                (
                  a: StandardContentReportSlide,
                  b: StandardContentReportSlide,
                ) =>
                  ChartHelper.ORDER[
                    ChartHelper.GetChartVariantFromUrl(a.plot?.url)
                  ] -
                  ChartHelper.ORDER[
                    ChartHelper.GetChartVariantFromUrl(b.plot?.url)
                  ],
              )
              .map((slide: StandardContentReportSlide, slideIndex) => (
                <Box
                  key={`page-${pageNumber}-slide-${slideIndex}`}
                  sx={{
                    display: "flex",
                    gap: "12px",
                    height: "100%",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column-reverse",
                    },
                  }}
                >
                  {!isMobile && (
                    <PageBox className="scrollable">
                      <PageBoxHeader title={slide.header} />
                      <PageBoxContent sx={{ overflow: "auto" }}>
                        <MarkdownText text={slide.description} />
                      </PageBoxContent>
                    </PageBox>
                  )}
                  <Box>
                    <Chart
                      sx={{ height: "100%" }}
                      variant={ChartHelper.GetChartVariantFromUrl(
                        slide.plot?.url,
                      )}
                      url={slide.plot?.url}
                      json_body={slide.plot?.json_body}
                    />
                  </Box>
                </Box>
              ))}
          </SwipeableContent>
        </Box>
      </PageContent>
    </Page>
  );
}
