import React, { useState, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import { useAuth0 } from "@auth0/auth0-react";
import VolumeUpOutlined from "@mui/icons-material/VolumeUpOutlined";
import StopCircle from "@mui/icons-material/StopCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { api } from "../../../api/twelve";

const AudioPlayerButton = ({ text }: { text: string }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setAudioUrl] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlay = async () => {
    if (playing && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setPlaying(false);
    } else {
      setLoading(true);
      try {
        const response = await api.textToSpeech(
          await getAccessTokenSilently(),
          text,
        );
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);

        audioRef.current = new Audio(url);
        audioRef.current.play();
        setPlaying(true);

        // Reset states when audio ends
        audioRef.current.onended = () => {
          setPlaying(false);
          URL.revokeObjectURL(url);
          setAudioUrl(null);
        };
      } catch (error) {
        console.error("Failed to fetch audio:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <IconButton onClick={handlePlay}>
      {loading ? (
        <CircularProgress size={24} />
      ) : playing ? (
        <StopCircle fontSize="small" />
      ) : (
        <VolumeUpOutlined fontSize="small" />
      )}
    </IconButton>
  );
};

export default AudioPlayerButton;
