import {
  Container,
  ContainerProps,
  Box,
  BoxProps,
  styled,
} from "@mui/material";
import { ReactComponent as SvgLogo } from "../../shared/icons/logo.svg";
import SignIn from "./SignIn";
import ChatExamples from "./ChatExamples";

const LogoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: "18px",
  left: "32px",
  [theme.breakpoints.up("md")]: {
    left: "auto",
    right: "32px",
  },
}));

const MainContainer = styled(Container)<ContainerProps>(({ theme }) => ({
  paddingLeft: "0px!important",
  paddingRight: "0px!important",
  display: "flex",
  width: "100vw",
  height: "calc(var(--vh, 1vh) * 100)",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.up("md")]: {
    maxWidth: "unset",
    backgroundColor: [theme.palette.primary.dark],
    color: [theme.palette.colors.accent],
  },
}));

export default function SignInPage() {
  return (
    <MainContainer>
      <LogoContainer>
        <SvgLogo width={"120px"} height={"40px"} />
      </LogoContainer>
      <SignIn />
      <ChatExamples />
    </MainContainer>
  );
}
