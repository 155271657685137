import {
  alpha,
  Box,
  BoxProps,
  ListItemButton,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as NewConversationIcon } from "../icons/new-conversation.svg";

const Container = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "30px",
}));

const Icon = styled(Box)(({ theme }) => ({
  position: "relative",
  top: "1px",
  height: "40px",
  "& *": {
    fill: alpha(theme.palette.text.primary, 0.87),
  },
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 700,
  fontSize: "14px",
  color: alpha(theme.palette.text.primary, 0.87),
  paddingRight: "8px",
  letterSpacing: "0.4px",
}));

interface ListItemIconButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export const ListItemIconButton = (props: ListItemIconButtonProps) => {
  const { onClick, children } = props;
  return (
    <ListItemButton LinkComponent={RouterLink} onClick={onClick}>
      <Container>
        <Text>{children}</Text>
        <Icon>
          <NewConversationIcon />
        </Icon>
      </Container>
    </ListItemButton>
  );
};
