import { Box } from "@mui/material";
import PlayerScoutingReport from "./scouting/ScoutingReport";
import { useEffect, useState, useRef } from "react";
import { api } from "../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { ReportContent } from "./models";
import LoadingScreen from "./shared/LoadingScreen";

export default function ReportPage() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(true);
  const [reportContent, setReportContent] = useState<ReportContent | undefined>(
    undefined,
  );
  const { reportId } = useParams();
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const getReport = async () => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const data = await api.getReportContentById(
          token,
          Number(reportId),
          controller.signal,
        );

        setReportContent(data);

        setLoading(false);
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      }
    };

    getReport();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, reportId]);

  const renderReport = () => {
    switch (reportContent?.report_type) {
      case "SCOUT_REPORT":
        return <PlayerScoutingReport {...reportContent} />;
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.customColors.report.background,
      }}
    >
      {loading ? <LoadingScreen /> : renderReport()}
    </Box>
  );
}
