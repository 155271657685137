import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.locale("en");

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

export default class DateTimeHelper {
  private static getOrdinalSuffix(day: number) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  public static isToday(date: string | Date): Boolean {
    return dayjs(date).isToday();
  }

  public static isYesterday(date: string | Date): Boolean {
    return dayjs(date).isYesterday();
  }

  public static isLast7Days(date: string | Date): Boolean {
    const today = dayjs();
    return (
      dayjs(date).isSameOrAfter(today.subtract(7, "day")) &&
      dayjs(date).isBefore(today, "day")
    );
  }

  public static isSameMonth(date: string | Date | undefined): Boolean {
    if (!date) return false;
    const today = dayjs();
    return dayjs(date).isSame(today, "month");
  }

  public static isSameYear(date: string | Date): Boolean {
    const today = dayjs();
    return dayjs(date).isSame(today, "year");
  }

  public static format(
    date: string | Date | undefined,
    format: string,
  ): string {
    if (!date) return "";
    return dayjs(date).format(format);
  }

  public static getCETTime(date: string | Date) {
    return dayjs(date).tz("Europe/Berlin").format("HH:mm");
  }

  public static format1(date: string | Date) {
    const day = dayjs(date).date();
    const ordinalSuffix = this.getOrdinalSuffix(day);
    return dayjs(date).format(`D[${ordinalSuffix}] [of] MMMM YYYY`);
  }
}
