import React, { useState, useRef } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import MicOutlined from "@mui/icons-material/MicOutlined";
import StopCircle from "@mui/icons-material/StopCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../../api/twelve";
import { config } from "../../../config";

interface AudioRecordButtonProps {
  onTranscription: (text: string) => void;
}

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: -1px;
`;

const StyledAudio = styled.audio`
  height: 30px;
  position: relative;
  top: -1px;
`;

const AudioRecordButton: React.FC<AudioRecordButtonProps> = ({
  onTranscription,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const handleRecord = async () => {
    if (recording) {
      mediaRecorderRef.current?.stop();
      setRecording(false);
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorder.onstop = async () => {
          setLoading(true);
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/mp4",
          });
          setAudioUrl(URL.createObjectURL(audioBlob)); // Set audio URL for playback

          await submitAudio(audioBlob);
          setLoading(false);
        };

        mediaRecorder.start();
        setRecording(true);
      } catch (error) {
        console.error("Microphone access denied:", error);
      }
    }
  };

  const submitAudio = async (audioBlob: Blob) => {
    try {
      const formData = new FormData();
      formData.append("audio", audioBlob, "recording.mp4");
      const text = await api.speechToText(
        await getAccessTokenSilently(),
        formData,
      );
      onTranscription(text);
    } catch (error) {
      console.error("Failed to submit audio:", error);
    }
  };

  return (
    <>
      <StyledIconButton onClick={handleRecord}>
        {loading ? (
          <CircularProgress size={24} />
        ) : recording ? (
          <StopCircle fontSize="small" />
        ) : (
          <MicOutlined fontSize="small" />
        )}
      </StyledIconButton>

      {audioUrl && config.debug_speech && (
        <div>
          <StyledAudio controls src={audioUrl}></StyledAudio>
        </div>
      )}
    </>
  );
};

export default AudioRecordButton;
