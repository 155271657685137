import { configureStore } from "@reduxjs/toolkit";
import viewReducer from "./viewReducer";
import conversationsReducer from "./conversationsReducer";
import reportsReducer from "../sections/reports/store/reportsReducer";

const store = configureStore({
  reducer: {
    view: viewReducer,
    conversations: conversationsReducer,
    reports: reportsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
