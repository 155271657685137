import { useSearchParams } from "react-router-dom";
import { Chart } from "../shared/components/charts/Chart";
import ChartHelper from "../shared/helpers/chart.helper";

export default function ChartPage() {
  const [searchParams] = useSearchParams();
  let plots: any = [];
  const data = searchParams.get("q");
  if (data) {
    try {
      plots = JSON.parse(data);
    } catch {
      plots = [];
    }
  }

  return (
    <div
      className="charts-container"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      {plots.map((p: any, i: number) => {
        return (
          <Chart
            className="chart"
            url={p.url}
            key={`chart _${i}`}
            variant={ChartHelper.GetChartVariantFromUrl(p.url)}
            json_body={p.json_body}
            size={"sm"}
          />
        );
      })}
    </div>
  );
}
