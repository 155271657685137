import {
  Box,
  Typography,
  ListItemButton,
  ListItemText,
  Link,
  useTheme,
  alpha,
} from "@mui/material";
import React from "react";
import { PageBox, PageBoxContent, PageBoxHeader } from "./ReportLayout";
import { ReportPage } from "../models";

export function TableOfContent({
  pages,
  showPlaceholder = true,
}: {
  pages: Array<ReportPage> | undefined;
  showPlaceholder?: boolean;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        width: "100%",
        height: "calc(100% - 1px)",
        [theme.breakpoints.down("md")]: {
          height: "unset",
        },
      }}
    >
      <PageBox sx={{ flexGrow: 1, flexBasis: "50%" }}>
        <PageBoxHeader title="Table of content" />
        <PageBoxContent sx={{ overflow: "auto" }}>
          {pages?.map((page, index) => (
            <React.Fragment key={`table-of-content-row-${index}`}>
              <ListItemButton
                component={Link}
                href={""}
                sx={{
                  width: "100%",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
                onClick={(event) => {
                  event.preventDefault();
                  const element = document.getElementById(`page-${index + 2}`);
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                <ListItemText>
                  <Typography>{page.header}</Typography>
                </ListItemText>
              </ListItemButton>
            </React.Fragment>
          ))}
        </PageBoxContent>
      </PageBox>
      {showPlaceholder && (
        <Box
          sx={{
            width: "50%",
            borderRadius: "8px",
            background: `
                    linear-gradient(
                        0deg,
                        ${alpha(theme.palette.customColors.visualisation.background2, 0.75)} 0%,
                        ${alpha(theme.palette.customColors.visualisation.background2, 0.75)} 100%
                    ),
                    url(/images/report-placeholder.png) center no-repeat
                `,
            backgroundSize: "cover",
            position: "relative",
          }}
        />
      )}
    </Box>
  );
}
