import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  List,
  ListItemText,
  CircularProgress,
  InputAdornment,
  Typography,
  ListItemButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { api } from "../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../shared/components/ResponsiveDialog";

interface SearchPlayer {
  id: number;
  name: string;
  team: string;
}

interface SearchPlayersProps {
  open: boolean;
  onClose: () => void;
  onSelect: (player: SearchPlayer) => void;
}

const SearchPlayerModal: React.FC<SearchPlayersProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState("");
  const [players, setPlayers] = useState<SearchPlayer[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setSearchQuery("");
    setPlayers([]);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        const result = await api.searchPlayers(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        setPlayers(result);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchTerm?.length < 5) {
      if (!debouncedSearchTerm.length) {
        setPlayers([]);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          overflow: "hidden",
        }}
      >
        <Box>
          <TextField
            fullWidth
            variant="standard"
            placeholder="Search..."
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                "&:before": {
                  borderBottom: "1px dotted",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "1px dotted",
                },
                "&:after": {
                  borderBottom: "1px dotted",
                },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            flex: "1",
            overflow: "hidden",
          }}
        >
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress size={"small"} />
            </Box>
          ) : debouncedSearchTerm.length ? (
            players.length > 0 ? (
              <List
                className="scrollable"
                dense
                sx={{
                  overflow: "auto",
                  height: "100%",
                  maxHeight: isMobile ? "50vh" : "75vh",
                }}
              >
                <ListItemText>PLAYERS</ListItemText>
                {players.map((player, index) => (
                  <ListItemButton
                    key={`player-${index}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    onClick={() => {
                      onSelect(player);
                      onClose();
                    }}
                  >
                    <ListItemText
                      sx={{
                        width: "100%",
                        fontSize: "12px",
                        color: "text.disabled",
                      }}
                      primary={player.team}
                    />
                    <ListItemText
                      sx={{
                        width: "100%",
                      }}
                      primary={player.name}
                    />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="text.disabled">
                No players found
              </Typography>
            )
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: "text.disabled",
                fontSize: "16px",
              }}
            >
              Find players by their first name and/or last name.
            </Typography>
          )}
        </Box>
      </Box>
    </ResponsiveDialog>
  );
};

export default SearchPlayerModal;
