import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { ReportTemplate, ReportTemplateProperty } from "../../models";
import SelectTemplateProperties from "./SelectTemplateProperties";
import TemplatePropertyIcon from "./TemplatePropertyIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "../../../../shared/contexts/SnackbarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { addItem, updateItem } from "../../store/slices/reportTemplatesSlice";
import { api } from "../../../../api/twelve";
import StringHelper from "../../../../shared/helpers/string.helper";
import {
  SortableList,
  SortableItem,
} from "../../../../shared/components/SortableList";

export default function ReportTemplateForm({
  variant = "create",
}: {
  variant: "create" | "update";
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();

  const [template, setTemplate] = useState<ReportTemplate>({
    id: 0,
    name: "",
    structure: {
      properties: [],
      sections: [],
    },
  });

  const [errors, setErrors] = useState({
    name: false,
    properties: false,
    noSections: false,
    sections: [
      {
        header: false,
        noQuestions: false,
        questions: [false],
      },
    ],
  });

  useEffect(() => {
    if (variant === "update" && location.state?.report) {
      const template = location.state.report as ReportTemplate;
      setErrors({
        name: false,
        properties: false,
        noSections: false,
        sections: template.structure.sections.map(() => ({
          header: false,
          noQuestions: false,
          questions: template.structure.sections.map(() => false),
        })),
      });
      setTemplate(structuredClone(template));
    }
  }, [variant, location]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: value.trim() === "",
    }));
  };

  const handlePropertySelect = (property: ReportTemplateProperty) => {
    if (template.structure.properties?.some((p) => p.type === property.type)) {
      return;
    }
    setTemplate({
      ...template,
      structure: {
        properties: [...template.structure.properties, property],
        sections: template.structure.sections,
      },
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      properties: false,
    }));
  };

  const handleSectionNameChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTemplate((prevTemplate) => {
        const newSections = [...prevTemplate.structure.sections];
        newSections[index].header = value;
        return {
          ...prevTemplate,
          structure: {
            ...prevTemplate.structure,
            sections: newSections,
          },
        };
      });
      setErrors((prevErrors) => {
        const newSectionErrors = [...prevErrors.sections];
        newSectionErrors[index].header = value.trim() === "";
        return {
          ...prevErrors,
          sections: newSectionErrors,
        };
      });
    };

  const handleSubquestionChange =
    (sectionIndex: number, questionIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTemplate((prevTemplate) => {
        const newSections = [...prevTemplate.structure.sections];
        newSections[sectionIndex].questions[questionIndex] = {
          question: value,
        };
        return {
          ...prevTemplate,
          structure: {
            ...prevTemplate.structure,
            sections: newSections,
          },
        };
      });
      setErrors((prevErrors) => {
        const newSectionErrors = [...prevErrors.sections];
        newSectionErrors[sectionIndex].questions[questionIndex] =
          value.trim() === "";
        return {
          ...prevErrors,
          sections: newSectionErrors,
        };
      });
    };

  const handleSubquestionAdd = (sectionIndex: number) => {
    setTemplate((prevTemplate) => {
      const newSections = [...prevTemplate.structure.sections];
      newSections[sectionIndex].questions.push({ question: "" });
      return {
        ...prevTemplate,
        structure: {
          ...prevTemplate.structure,
          sections: newSections,
        },
      };
    });
    setErrors((prevErrors) => {
      const newSectionErrors = [...prevErrors.sections];
      newSectionErrors[sectionIndex].noQuestions = false;
      newSectionErrors[sectionIndex].questions.push(false);
      return {
        ...prevErrors,
        sections: newSectionErrors,
      };
    });
  };

  const handleSectionAdd = () => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      structure: {
        ...prevTemplate.structure,
        sections: [
          ...prevTemplate.structure.sections,
          {
            header: "",
            questions: [{ question: "" }],
          },
        ],
      },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      noSections: false,
      sections: [
        ...prevErrors.sections,
        {
          header: false,
          noQuestions: false,
          questions: [false],
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    const nameError = template.name.trim() === "";
    const propertiesError = template.structure.properties.length === 0;
    const noSectionsError = template.structure.sections.length === 0;
    const sectionErrors = template.structure.sections.map((section) => ({
      header: section.header.trim() === "",
      noQuestions: section.questions.length === 0,
      questions: section.questions.map(
        (subquestion) => subquestion.question.trim() === "",
      ),
    }));
    setErrors({
      name: nameError,
      properties: propertiesError,
      noSections: noSectionsError,
      sections: sectionErrors,
    });

    if (
      !nameError &&
      // !propertiesError &&
      !noSectionsError &&
      !sectionErrors.some(
        (section) =>
          section.header ||
          section.noQuestions ||
          section.questions.some((error) => error),
      )
    ) {
      try {
        if (variant === "create") {
          dispatch(
            addItem(
              await api.addReportTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        } else {
          dispatch(
            updateItem(
              await api.updateReportTemplate(
                await getAccessTokenSilently(),
                template,
              ),
            ),
          );
        }
      } catch (error: any) {
        showSnackbar(`${error.message}`, {}, "error");
        return;
      }
      showSnackbar(`${template.name} was saved`);
      navigate("/report");
    } else {
      console.log("Validation errors:", errors);
    }
  };

  return (
    <Box
      className="scrollable"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: "16px",
        overflow: "auto",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          maxWidth: "640px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
            pb: 1,
            [theme.breakpoints.down("md")]: {
              pb: 0,
            },
          }}
        >
          <Button variant="text" color="primary" onClick={handleSubmit}>
            Save template
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => navigate("/report")}
          >
            Cancel
          </Button>
        </Box>
        <Box
          sx={{
            padding: "0 8px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <TextField
                variant="standard"
                fullWidth
                required
                placeholder="Template name"
                value={template.name}
                onChange={handleNameChange}
                error={errors.name}
                helperText={errors.name ? "This field is required" : ""}
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    fontSize: "24px",
                    fontWeight: 700,
                    "& .MuiInputBase-root": {
                      borderBottom: "none",
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <SortableList
                onSortEnd={(newOrder) => {
                  template.structure.properties = newOrder.map(
                    (index) => template.structure.properties[Number(index)],
                  );
                }}
              >
                {template.structure.properties.map((property, index) => (
                  <SortableItem id={String(index)} key={`property-${index}`}>
                    <Box
                      key={`property-${index}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                          color: (theme) => theme.palette.text.secondary,
                          width: "200px",
                          cursor: "pointer",
                          "&:hover .delete-icon": {
                            display: "block",
                          },
                          "&:hover .icon": {
                            display: "none",
                          },
                        }}
                      >
                        <Box>
                          <Box
                            className="icon"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "2px 4px 0 4px",
                              boxSizing: "border-box",
                            }}
                          >
                            <TemplatePropertyIcon property={property} />
                          </Box>
                          <IconButton
                            className="delete-icon"
                            sx={{
                              display: "none",
                              padding: "2px 4px 0 4px",
                            }}
                            onClick={() => {
                              console.log("delete property", index);
                              template.structure.properties.splice(index, 1);
                              setTemplate({
                                ...template,
                              });
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                properties:
                                  template.structure.properties.length === 0,
                              }));
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                        <Typography variant="body1" sx={{ padding: "4px 0" }}>
                          {StringHelper.normalizeKey(property.type)}
                        </Typography>
                      </Box>
                      {/* <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        disabled
                      >
                        Empty
                      </Button> */}
                    </Box>
                  </SortableItem>
                ))}
              </SortableList>
            </Box>
            <Box>
              <SelectTemplateProperties onSelect={handlePropertySelect} />
              {/* {errors.properties && (
                <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                  Please select at least one property
                </Typography>
              )} */}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <SortableList
                onSortEnd={(newOrder) => {
                  template.structure.sections = newOrder.map(
                    (index) => template.structure.sections[Number(index)],
                  );
                  setTemplate({ ...template });
                  errors.sections = newOrder.map(
                    (index) => errors.sections[Number(index)],
                  );
                  setErrors({ ...errors });
                }}
              >
                {template.structure.sections.map((section, sectionIndex) => (
                  <SortableItem
                    id={String(sectionIndex)}
                    key={`section-${sectionIndex}`}
                  >
                    <Box
                      key={`section-${sectionIndex}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <TextField
                        variant="standard"
                        fullWidth
                        required
                        placeholder="Section header"
                        value={section.header}
                        error={errors.sections[sectionIndex].header}
                        helperText={
                          errors.sections[sectionIndex].header
                            ? "This field is required"
                            : ""
                        }
                        onChange={handleSectionNameChange(sectionIndex)}
                        InputLabelProps={{
                          shrink: false,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            fontSize: "16px",
                            "& .MuiInputBase-root": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              fontWeight: 600,
                            },
                            "&:hover .delete-icon": {
                              visibility: "visible",
                              opacity: 1,
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                className="delete-icon"
                                sx={{
                                  visibility: "hidden",
                                  opacity: 0,
                                  transition:
                                    "opacity 0.3s ease, visibility 0.3s ease",
                                }}
                                onClick={() => {
                                  template.structure.sections.splice(
                                    sectionIndex,
                                    1,
                                  );
                                  setTemplate({
                                    ...template,
                                  });
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <SortableList
                          onSortEnd={(newOrder) => {
                            template.structure.sections[
                              sectionIndex
                            ].questions = newOrder.map(
                              (index) =>
                                template.structure.sections[sectionIndex]
                                  .questions[Number(index)],
                            );
                            setTemplate({ ...template });

                            errors.sections[sectionIndex].questions =
                              newOrder.map(
                                (index) =>
                                  errors.sections[sectionIndex].questions[
                                    Number(index)
                                  ],
                              );
                            setErrors({ ...errors });
                          }}
                        >
                          {section.questions.map((question, questionIndex) => (
                            <SortableItem
                              id={String(questionIndex)}
                              key={`subquestion-${questionIndex}`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  padding: "2px 12px 2px 0",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  fullWidth
                                  required
                                  placeholder="Question"
                                  value={question.question}
                                  error={
                                    errors.sections[sectionIndex].questions[
                                      questionIndex
                                    ]
                                  }
                                  helperText={
                                    errors.sections[sectionIndex].questions[
                                      questionIndex
                                    ]
                                      ? "This field is required"
                                      : ""
                                  }
                                  onChange={handleSubquestionChange(
                                    sectionIndex,
                                    questionIndex,
                                  )}
                                  InputLabelProps={{
                                    shrink: false,
                                  }}
                                  InputProps={{
                                    sx: {
                                      fontSize: "16px",
                                      "&:hover .delete-icon": {
                                        visibility: "visible",
                                        opacity: 1,
                                      },
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          edge="end"
                                          className="delete-icon"
                                          sx={{
                                            visibility: "hidden",
                                            opacity: 0,
                                            transition:
                                              "opacity 0.3s ease, visibility 0.3s ease",
                                          }}
                                          onClick={() => {
                                            template.structure.sections[
                                              sectionIndex
                                            ].questions.splice(
                                              questionIndex,
                                              1,
                                            );
                                            setTemplate({
                                              ...template,
                                            });
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </SortableItem>
                          ))}
                        </SortableList>
                      </Box>
                      <Box>
                        <Button
                          variant="text"
                          color="inherit"
                          size="small"
                          startIcon={<AddIcon />}
                          onClick={() => handleSubquestionAdd(sectionIndex)}
                        >
                          Add subquestion
                        </Button>
                        {errors.sections[sectionIndex].noQuestions && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "0.75rem" }}
                          >
                            Please add at least one subquestion
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </SortableItem>
                ))}
              </SortableList>
            </Box>
            <Box>
              <Button
                variant="text"
                color="inherit"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleSectionAdd()}
              >
                Add section
              </Button>
              {errors.noSections && (
                <Typography color="error" sx={{ fontSize: "0.75rem" }}>
                  Please add at least one section
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
