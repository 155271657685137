import React from "react";
import { Button, ButtonGroup, SxProps, useTheme } from "@mui/material";
import { PaletteModeContext, ThemeMode } from "../../themes/theme";

export default function SelectTheme() {
  const theme = useTheme();
  const paletteMode = React.useContext(PaletteModeContext);

  const sx = (mode: ThemeMode): SxProps => {
    if (mode !== paletteMode.mode) return {};
    return {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
    };
  };

  return (
    <ButtonGroup
      variant="text"
      color="primary"
      sx={{
        width: "100%",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
        "& .MuiButton-root": {
          flexGrow: 1,
          borderRight: `1px solid ${theme.palette.divider}`,
          "&:last-of-type": {
            borderRight: "none",
          },
        },
      }}
    >
      <Button sx={sx("light")} onClick={() => paletteMode.setMode("light")}>
        Light
      </Button>
      <Button sx={sx("dark")} onClick={() => paletteMode.setMode("dark")}>
        Dark
      </Button>
      <Button sx={sx("system")} onClick={() => paletteMode.setMode("system")}>
        System
      </Button>
    </ButtonGroup>
  );
}
