import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppSectionType } from "../models";

interface ViewState {
  sidebar: {
    opened: boolean;
  };
  section: AppSectionType;
}

const initialState: ViewState = {
  sidebar: {
    opened: true,
  },
  section: "conversation",
};

const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean | undefined>) {
      state.sidebar.opened =
        action.payload === undefined ? !state.sidebar.opened : action.payload;
    },
    setSection(state, action: PayloadAction<AppSectionType>) {
      state.section = action.payload;
    },
  },
});

export const { toggleSidebar, setSection } = viewSlice.actions;

export default viewSlice.reducer;
