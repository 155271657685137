export class User {
  id: string;
  club_name: string;
  permissions: string[];

  constructor(id: string, club_name: string, permissions: string[] = []) {
    this.id = id;
    this.club_name = club_name;
    this.permissions = permissions;
  }

  chekPermissions(permissions: Array<string> | string): boolean {
    if (typeof permissions === "string") {
      return this.permissions.includes(permissions);
    }
    const set = new Set(this.permissions);
    return permissions.some((element) => set.has(element));
  }
}
