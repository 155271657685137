import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from ".";
import { ConversationModel } from "../models";
import { api } from "../api/twelve";

interface ConversationsState {
  items: Array<ConversationModel>;
  loading: boolean;
  canLoadingMore: boolean;
  selectedConversation: ConversationModel | null;
  error: string | null;
}

const initialState: ConversationsState = {
  items: [],
  loading: false,
  canLoadingMore: true,
  error: null,
  selectedConversation: null,
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    fetchItemsStart: (state) => {
      state.loading = true;
      state.canLoadingMore = true;
    },
    fetchItemsSuccess: (
      state,
      action: PayloadAction<{
        items: Array<ConversationModel>;
        append?: boolean;
      }>,
    ) => {
      if (action.payload.append) {
        state.items = [...state.items, ...action.payload.items];
      } else {
        state.items = action.payload.items;
      }
      state.canLoadingMore = action.payload.items.length > 0;
      state.loading = false;
    },
    failure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addItem: (state, action: PayloadAction<ConversationModel>) => {
      state.items.unshift(action.payload);
    },
    updateItem: (state, action: PayloadAction<ConversationModel>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id,
      );
      if (index !== -1) {
        state.items = [
          ...state.items.slice(0, index),
          action.payload, // new item
          ...state.items.slice(index + 1),
        ];
      }
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    selectConversation: (state, action: PayloadAction<ConversationModel>) => {
      state.selectedConversation = action.payload;
    },
  },
});

export default conversationsSlice.reducer;

export const { selectConversation } = conversationsSlice.actions;

// Thunk actions for API calls
export const fetchConversations: any =
  (token: string, createdBefore?: Date) => async (dispatch: AppDispatch) => {
    try {
      dispatch(conversationsSlice.actions.fetchItemsStart());
      const response = await api.listConversations(
        token,
        undefined,
        createdBefore,
      );
      dispatch(
        conversationsSlice.actions.fetchItemsSuccess({ items: response }),
      );
    } catch (error: any) {
      console.error("Failed to fetch conversations:", error);
      dispatch(conversationsSlice.actions.failure(error.message));
    }
  };

export const fetchMoreConversations: any =
  (token: string, createdBefore?: Date) => async (dispatch: AppDispatch) => {
    try {
      dispatch(conversationsSlice.actions.fetchItemsStart());
      const response = await api.listConversations(
        token,
        undefined,
        createdBefore,
      );
      dispatch(
        conversationsSlice.actions.fetchItemsSuccess({
          items: response,
          append: true,
        }),
      );
    } catch (error: any) {
      console.error("Failed to fetch more conversations:", error);
      dispatch(conversationsSlice.actions.failure(error.message));
    }
  };

export const addConversation: any =
  (token: string, name: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await api.createConversation(token, name);
      dispatch(conversationsSlice.actions.addItem(response));
      return response;
    } catch (error: any) {
      console.error("Failed to add conversation:", error);
      dispatch(conversationsSlice.actions.failure(error.message));
    }
  };

export const renameConversation: any =
  (token: string, conversationId: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await api.renameConversation(token, conversationId);
      dispatch(conversationsSlice.actions.updateItem(response));
    } catch (error: any) {
      dispatch(conversationsSlice.actions.failure(error.message));
    }
  };

export const deleteConversation: any =
  (token: string, conversationId: number) => async (dispatch: AppDispatch) => {
    try {
      await api.deleteConversation(token, conversationId);
      dispatch(conversationsSlice.actions.deleteItem(conversationId));
    } catch (error: any) {
      dispatch(conversationsSlice.actions.failure(error.message));
    }
  };
