import React, { useEffect } from "react";
import {
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { CustomRouterLink } from "../../../../shared/components/CustomRouterLink";
import { useAuth0 } from "@auth0/auth0-react";
import { fetch } from "../../store/slices/reportTemplatesSlice";

export default function SelectTemplate() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { items } = useSelector(
    (state: RootState) => state.reports.templates.list,
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getTemplates = async () => {
      dispatch(fetch(await getAccessTokenSilently()));
    };

    getTemplates();
  }, [getAccessTokenSilently, dispatch]);

  return (
    <>
      <Button
        variant="text"
        size="small"
        endIcon={<AddIcon />}
        onClick={handleClick}
      >
        Create new
      </Button>
      <Menu
        id="select-template-props-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: "210px",
            elevation: 1,
            background: (theme) => theme.palette.backgrounds.menu,
          },
        }}
      >
        <List
          dense
          className="scrollable"
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            padding: 0,
          }}
        >
          <ListSubheader sx={{ background: "transparent", pb: "4px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Templates
            </Typography>
          </ListSubheader>
          {items.map((report) => (
            <ListItemButton
              key={`report-${report.id}`}
              component={CustomRouterLink}
              to={{
                pathname: `/report/create`,
                state: { report },
              }}
            >
              <ListItemText>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: 500,
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {report.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
