import { useCallback } from "react";
import { ListItemIconButton } from "../../shared/components/ListItemIconButton";
import { config } from "../../config";
import { useNavigate, useRevalidator } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api/twelve";
import { BaseContext } from "../../models";
import { useDispatch } from "react-redux";
import { addConversation } from "../../store/conversationsReducer";
import { Button } from "@mui/material";

function createDummyContext(): BaseContext {
  const context: BaseContext = {
    version: "1",
  };
  return context;
}

export function CreateConversationButton({
  title = "New Chat",
  variant = "Button",
}: {
  title?: string;
  variant?: "Button" | "ListItemIconButton";
}) {
  const revalidator = useRevalidator();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreate = useCallback(() => {
    const fn = async () => {
      const conversationName = config.conversationNaming.default;
      const { id: conversationId } = await dispatch(
        await addConversation(await getAccessTokenSilently(), conversationName),
      );
      await api.createChat(
        await getAccessTokenSilently(),
        conversationId,
        conversationName,
        "RECEPTIONIST",
        createDummyContext(),
      );
      revalidator.revalidate();
      navigate(`/conversation/${conversationId}`);
    };
    fn();
  }, [getAccessTokenSilently, navigate, dispatch, revalidator]);

  return (
    <>
      {variant === "Button" ? (
        <Button onClick={handleCreate} variant="contained" size="large">
          {title}
        </Button>
      ) : (
        <ListItemIconButton onClick={handleCreate}>{title}</ListItemIconButton>
      )}
    </>
  );
}
