import { useEffect, useRef } from "react";
import { Tooltip, useTooltip } from "./Tooltip";
import {
  ChartBody,
  ChartContainer,
  ChartFooter,
  ChartHeader,
  PitchChartWrapper,
} from "./ChartLayout";
import { useTheme } from "@mui/material/styles";
import * as d3 from "d3";
import { Box, alpha } from "@mui/material";

type ThirdsPitchChartProps = {
  title: string;
  subTitle?: string;
  labels?: Array<string>;
  footerText?: string;
  data: {
    points: Array<any>;
    rating: Array<any>;
  };
};

export function ThirdsPitchChart(props: ThirdsPitchChartProps) {
  const { tooltip, showTooltip, hideTooltip } = useTooltip();
  const ref = useRef(null);
  const theme = useTheme();
  const colors = theme.palette.customColors;

  const x = (value: number) => value * 1.05;
  const y = (value: number) => value * 0.68;

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove();
    props.data.rating.forEach((rating, index) => {
      const g = d3.select(ref.current).append("g");
      const rect = g
        .append("rect")
        .attr("x", ((0.5 + index) * 105) / 3)
        .attr("y", 0.68 * (100 - 10))
        .attr("height", 6)
        .attr("fill", colors.teamColors.main)
        .attr("fill-opacity", 0.5);
      const text: any = g
        .append("text")
        .attr("x", ((0.5 + index) * 105) / 3)
        .attr("y", 0.68 * (100 - 10))
        .attr("fill", alpha(colors.text, colors.opacity.text.primary))
        .attr("font-size", "4px")
        .attr("text-anchor", "middle")
        .attr("dy", "0.35em")
        .text(`${rating.toFixed(2)} xT`);
      const width = text.node().getBBox().width + 2;
      const height = text.node().getBBox().height;
      rect
        .attr("width", width)
        .attr("height", height)
        .attr("transform", `translate(-${width / 2},-${height / 2})`);
    });
  }, [colors, props.data.rating]);

  return (
    <>
      <ChartContainer {...props}>
        <ChartHeader {...props} />
        <ChartBody>
          <PitchChartWrapper {...props}>
            <g>
              <g>
                {props.data.points.map((point: any, index: number) => {
                  return (
                    <circle
                      cx={x(point.x)}
                      cy={y(point.y)}
                      r={1}
                      fill="#FFFFFF"
                      onMouseMove={(event: any) => {
                        const { pageX: x, pageY: y } = event;
                        showTooltip(
                          `
                    Cristiano Ronaldo <br/>
                    Value: ${point.value.toFixed(2)}`,
                          { x, y },
                        );
                      }}
                      onMouseOut={() => hideTooltip()}
                    ></circle>
                  );
                })}
              </g>
              <g ref={ref}></g>
            </g>
          </PitchChartWrapper>
        </ChartBody>
        {props.footerText ? (
          <ChartFooter>
            <Box
              sx={{
                color: alpha(
                  theme.palette.customColors.text,
                  theme.palette.customColors.opacity.text.secondary,
                ),
              }}
            >
              {props.footerText}
            </Box>
          </ChartFooter>
        ) : (
          <></>
        )}
      </ChartContainer>
      <Tooltip tooltip={tooltip} />
    </>
  );
}
