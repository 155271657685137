import { Box, BoxProps, styled } from "@mui/material";
import { useState, useCallback } from "react";

const TooltipBox = styled(Box)<BoxProps>(({ theme }) => ({
  position: "fixed",
  fontFamily: theme.typography.fontFamily,
  fontSize: "10px",
  lineHeight: "14px",
  fontStyle: "normal",
  background: theme.palette.customColors.tooltip.background,
  borderRadius: "6px",
  padding: "4px 8px",
  pointerEvents: "none",
  transform: "translate(-100%, 0)",
  zIndex: 1000,
  color: theme.palette.customColors.text,
  fontWeight: 500,
}));

export function useTooltip() {
  const [tooltip, setTooltip] = useState({
    show: false,
    content: null,
    position: { x: 0, y: 0 },
  });

  const showTooltip = useCallback(
    (content: any, position: { x: number; y: number }) => {
      setTooltip({ show: true, content, position });
    },
    [],
  );

  const hideTooltip = useCallback(() => {
    setTooltip({ show: false, content: null, position: { x: 0, y: 0 } });
  }, []);

  return {
    tooltip,
    showTooltip,
    hideTooltip,
  };
}

export function Tooltip({ tooltip }: any) {
  if (!tooltip.show) return null;

  return (
    <TooltipBox
      sx={{
        top: `${tooltip.position.y + 5}px`,
        left: `${tooltip.position.x}px`,
      }}
      dangerouslySetInnerHTML={{ __html: tooltip.content }}
    />
  );
}
