import React, { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Page,
  PageBox,
  PageBoxContent,
  PageContent,
  PageHeader,
} from "../../shared/ReportLayout";
import { GlossaryItem } from "../../models";

export default function GlossaryPage({
  items = [],
}: {
  items?: Array<GlossaryItem>;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded, setExpanded] = useState(false);
  const glossaryChunksCount = 1;

  const chunks = (arr: Array<any> = [], n: number = 1): Array<Array<any>> => {
    const result = [];
    for (let i = 0; i < arr.length; i += n) {
      result.push(arr.slice(i, i + n));
    }

    return result;
  };

  const handleToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <Page sx={{ position: "relative" }}>
      <PageHeader title="Glossary">
        {isMobile ? (
          <Button variant="text" sx={{ color: "white" }} onClick={handleToggle}>
            {expanded ? "Hide" : "Show"}
          </Button>
        ) : (
          <></>
        )}
      </PageHeader>
      <PageContent>
        <Collapse
          in={!isMobile || expanded}
          timeout={{ appear: 1000, enter: 1000, exit: 100 }}
        >
          <Grid container spacing={2}>
            {chunks(items, items.length / glossaryChunksCount).map(
              (chunk, chunkIndex) => (
                <Grid
                  item
                  key={`glossary-chunk-${chunkIndex}`}
                  xs={12}
                  md={12 / glossaryChunksCount}
                >
                  <PageBox
                    sx={{
                      overflo: "auto",
                      height: "calc(100vh - 174px)",
                      [theme.breakpoints.down("md")]: {
                        height: "100%",
                      },
                    }}
                  >
                    <PageBoxContent className="scrollable">
                      <Grid container spacing={2}>
                        {chunk.map((item, index) => (
                          <React.Fragment
                            key={`glossary-chunk-${chunkIndex}-${index}`}
                          >
                            <Grid item xs={12} md={4}>
                              <Typography component={"p"} variant="body2">
                                {item.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <Typography
                                component={"p"}
                                variant="body2"
                                sx={{ opacity: 0.6 }}
                              >
                                {item.definition}
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </PageBoxContent>
                  </PageBox>
                </Grid>
              ),
            )}
          </Grid>
        </Collapse>
      </PageContent>
    </Page>
  );
}
