import { PaletteMode, PaletteOptions, Theme, createTheme } from "@mui/material";
import React from "react";
import { CustomColors } from "./customColors";
import {
  darkModeApplicationPalette,
  lightModeApplicationPalette,
} from "./palettes";

export type ThemeMode = PaletteMode | "system";

export const PaletteModeContext = React.createContext({
  mode: "light" as ThemeMode,
  setMode: (mode: ThemeMode) => {},
});

declare module "@mui/material/styles" {
  interface Palette {
    disabled: Palette["primary"];
    backgrounds: {
      primary: string;
      secondary: string;
      tertiary: string;
      code: string;
      bagde1: string;
      menu: string;
    };
    colors: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }

  interface PaletteOptions {
    disabled?: PaletteOptions["primary"];
    backgrounds?: {
      primary: string;
      secondary: string;
      tertiary: string;
      code: string;
      bagde1: string;
      menu: string;
    };
    colors?: {
      accent?: string;
      bagde1: string;
    };
    customColors: CustomColors;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    disabled: true;
  }
}

const getPalette = (
  mode: ThemeMode = "light",
  prefersDarkMode: boolean,
  customer = undefined,
): PaletteOptions => {
  switch (mode) {
    case "system":
      if (prefersDarkMode) return darkModeApplicationPalette;
      return lightModeApplicationPalette;
    case "dark":
      return darkModeApplicationPalette;
    default:
      return lightModeApplicationPalette;
  }
};

export const createCustomTheme = (
  mode: ThemeMode = "light",
  prefersDarkMode: boolean,
  customer = undefined,
) => {
  return createTheme({
    palette: getPalette(mode, prefersDarkMode, customer),
    typography: {
      fontFamily: "Gilroy",
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            position: "fixed",
            background: "#002C1C",
            zIndex: theme.zIndex.drawer + 1,
          }),
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            fontSize: "0.875rem",
            borderRadius: "3px",
            textTransform: "unset",
          },
        },
      },
      MuiCircularProgress: {
        variants: [
          {
            props: {
              size: "small",
            },
            style: {
              width: "30px",
              height: "30px",
            },
          },
        ],
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            backgroundColor: "transparent",
          },
        },
      },
    },
  });
};

export const getGlobalStyles = (theme: Theme) => ({
  "@supports (scrollbar-color: auto)": {
    ".scrollable": {
      scrollbarColor: `${theme.palette.action.disabled} transparent`,
      scrollbarWidth: "thin",
    },
    ".scrollable:hover": {
      scrollbarColor: `${theme.palette.action.active} transparent`,
    },
  },
  "@supports selector(::-webkit-scrollbar)": {
    "::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: "8px",
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: "16px",
      backgroundColor: theme.palette.action.disabled,
    },
    "::-webkit-scrollbar-thumb:hover": {
      borderRadius: "16px",
      backgroundColor: theme.palette.action.active,
    },
  },
});
