import React, { useEffect } from "react";
import {
  alpha,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomRouterLink } from "../../../shared/components/CustomRouterLink";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fetchSavedReports } from "../store/slices/savedReportShortListSlice";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export function SavedReportShortList() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { reportId } = useParams<{ reportId: string }>();
  const { items } = useSelector(
    (state: RootState) => state.reports.saved.shortList,
  );

  useEffect(() => {
    const getReports = async () => {
      dispatch(fetchSavedReports(await getAccessTokenSilently()));
    };

    getReports();
  }, [getAccessTokenSilently, dispatch]);

  return (
    <>
      {items.length ? (
        <>
          <ListSubheader>Reports</ListSubheader>
          <List
            dense
            sx={{
              padding: 0,
              maxHeight: "186px",
              overflow: "auto",
              flexShrink: 0,
            }}
          >
            {items.map((report) => (
              <ListItemButton
                key={`report-${report.id}`}
                component={CustomRouterLink}
                to={{
                  pathname: `/saved-reports/${report.id}`,
                }}
                selected={report.id === Number(reportId)}
              >
                <ListItemText>
                  <Typography
                    sx={{
                      fontWeight:
                        report.id === Number(reportId) ? "600" : "200",
                      paddingLeft: "12px",
                      fontSize: "14px",
                      color: (theme) => theme.palette.text.primary,
                    }}
                  >
                    {report.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </>
      ) : (
        <></>
      )}
      <ListSubheader sx={{ p: 0 }}>
        <ListItemButton component={RouterLink} to={`/saved-reports`}>
          <ListItemText>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "14px",
                color: (theme) => alpha(theme.palette.text.primary, 0.87),
                display: "flex",
                letterSpacing: "0.4px",
              }}
            >
              All Reports <KeyboardArrowRightIcon fontSize="small" />
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListSubheader>
    </>
  );
}
