import { combineReducers } from "@reduxjs/toolkit";
import reportTemplatesReducer from "./slices/reportTemplatesSlice";
import reportListReducer from "./slices/reportListSlice";
import savedReportShortListReducer from "./slices/savedReportShortListSlice";
import savedReportListReducer from "./slices/savedReportListSlice";

const reportsReducer = combineReducers({
  templates: combineReducers({
    list: reportTemplatesReducer,
  }),
  saved: combineReducers({
    shortList: savedReportShortListReducer,
    list: savedReportListReducer,
  }),
  list: reportListReducer,
});

export default reportsReducer;
