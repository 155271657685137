import PlayerIcon from "@mui/icons-material/Person";
import SportsIcon from "@mui/icons-material/Sports";
import SecurityIcon from "@mui/icons-material/Security";
import TagIcon from "@mui/icons-material/Tag";
import { ReportTemplateProperty } from "../../models";

export default function TemplatePropertyIcon({
  property,
  fontSize = "small",
  color = "text.secondary",
}: {
  property: ReportTemplateProperty;
  fontSize?: "small" | "medium" | "large";
  color?: string;
}) {
  const icons: { [key: string]: JSX.Element } = {
    player: <PlayerIcon fontSize={fontSize} sx={{ color }} />,
    match: <SportsIcon fontSize={fontSize} sx={{ color }} />,
    team: <SecurityIcon fontSize={fontSize} sx={{ color }} />,
    tags: <TagIcon fontSize={fontSize} sx={{ color }} />,
  };

  return <>{icons[property.type]}</>;
}
