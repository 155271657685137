import React from "react";
import ReportLogo from "../shared/ReportLogo";
import {
  ReportContainer,
  LastPage,
  Page,
  PageHeader,
  PageContent,
} from "../shared/ReportLayout";
import { ReportContent, ReportPage } from "../models";
import TitlePage from "./components/TitlePage";
import StandardPage from "./components/StandardPage";
import GlossaryPage from "./components/GlossaryPage";
import PlayerSummaryPage from "./components/PlayerSummaryPage";
import { TableOfContent } from "../shared/TableOfContent";
import { useMediaQuery, useTheme } from "@mui/material";

export default function PlayerScoutingReport({
  content = { pages: [] },
}: ReportContent) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderPage = (page: ReportPage, pageNumber: number) => {
    switch (page.type) {
      case "title_page":
        return <TitlePage page={page} />;
      case "basic_page":
        return (
          <PlayerSummaryPage
            page={page}
            pageNumber={pageNumber}
            competition={content.competition}
          />
        );
      case "standard_page":
        return <StandardPage page={page} pageNumber={pageNumber} />;
      case "glossary_pages":
        return <GlossaryPage items={page.items} />;
      default:
        return <></>;
    }
  };

  return (
    <ReportContainer>
      {content.pages?.map((page, index) => (
        <React.Fragment key={`page-${index}`}>
          {renderPage(page, index)}
          {index === 1 && !isMobile ? (
            <Page>
              <PageHeader
                title="Table of content"
                subtitle={!isMobile ? page.subheader : undefined}
              />
              <PageContent>
                <TableOfContent
                  pages={content.pages?.filter(
                    (p) => p.type === "standard_page",
                  )}
                  showPlaceholder={!isMobile}
                />
              </PageContent>
            </Page>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
      <LastPage>
        <ReportLogo size="large" />
      </LastPage>
    </ReportContainer>
  );
}
