import { Config } from "./type";
import { local } from "./local";
import { dev } from "./dev";
import { prod } from "./prod";

const base = {
  conversationNaming: {
    default: "New chat",
    userMessageThreshold: 3,
  },
};

const FORCE_CONF: string | null = null; // Use this to force configuration when running locally

function getEnvConf(): Config {
  const hostname = window.location.hostname.toLowerCase();
  if (hostname === null) {
    throw new Error("Unable to determine hostname.");
  }

  switch (FORCE_CONF) {
    case "local":
      console.warn(`Using local configuration on hostname ${hostname}`);
      return local;
    case "dev":
      console.warn(`Using dev configuration on hostname ${hostname}`);
      return dev;
    case "prod":
      console.warn(`Using prod configuration on hostname ${hostname}`);
      return prod;
    case null:
      break;
  }

  // Return local when running on ngrok
  if (hostname.endsWith("ngrok-free.app")) {
    console.log("Using dev configuration for ngrok tunnel");
    return local;
  }

  switch (hostname) {
    case "localhost":
    case "127.0.0.1":
      console.log("Using local configuration");
      return local;
    case "twelvegptv2ui.z6.web.core.windows.net":
    case "gpt-v2-dev.twelve.football":
      console.log("Using dev configuration");
      return dev;
    case "gpt-v2.twelve.football":
      console.log("Using prod configuration");
      return prod;
    default:
      throw new Error(`No configuration for hostname ${hostname}`);
  }
}

export const config = {
  ...base,
  ...getEnvConf(),
};
